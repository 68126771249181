import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Container } from "../components/Container";
import { Divider } from "../components/Divider";
import { Headline } from "../components/Headline";
import { Loader } from "../components/Loader";
import { Page } from "../components/Page";
import NewsPage from "../components/NewsPage";
import { richText } from "../utils/richText";
import { colors } from "../utils/theme";
import url from "urlite/extra";
import { useCoreData } from "../utils/useCoreData";
import { ComingSoonPage } from "../components/ComingSoonPage";
import { useMobile } from "../utils/useMobile";
import { useLanguage } from "../utils/useLanguage";

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: 600,
    marginTop: "30px",
    marginBottom: "30px",
  },
  divider: {
    height: "2px",
  },
  image: {
    width: "100%",
  },
  withParagraph: {
    "& p": {
      marginBottom: "20px",
      lineHeight: "1.7",
    },
  },
  moreNews: {
    fontWeight: 300,
    textTransform: "uppercase",
  },
}));

export default function News({ location }) {
  const {
    search: { id },
  } = location && url.parse(location.search);

  const classes = useStyles();
  return (
    <Page>
      {id ? (
        <NewsPage id={id} classes={classes} />
      ) : (
        <MainNews classes={classes} />
      )}
    </Page>
  );
}

function MainNews({ classes }) {
  const {
    api: {
      data: { news, miscellaneous },
    },
  } = useCoreData();

  const mobile = useMobile();

  if (!news && !miscellaneous) return <Loader />;
  if (!news) return <ComingSoonPage />;

  // start with copying
  const allNews = news
    .map((x) => x)
    .sort((a, b) => {
      const dateA = new Date(a.first_publication_date);
      const dateB = new Date(b.first_publication_date);
      return dateA < dateB ? 1 : -1;
    });

  const mainNews = allNews.shift();
  const hasImage = !!mainNews.data.image.url;
  const otherNews = hasImage ? allNews.slice(0, 3) : allNews.slice(2, 5);
  return (
    <Container withToolbar withBottomPadding>
      <Typography variant="h4" classes={{ root: classes.mainTitle }} paragraph>
        {miscellaneous.news_title}
      </Typography>
      <Box {...getWrapperStyle(hasImage, mobile, !!otherNews.length, true)}>
        {hasImage && (
          <div>
            <img
              src={mainNews.data.image.url}
              alt={mainNews.data.image.alt}
              className={classes.image}
            />
          </div>
        )}
        <NewsBoxWithDivider
          news={mainNews}
          readMore={miscellaneous.read_more}
          isLast={hasImage}
          key={mainNews.id}
        />
        {!hasImage &&
          allNews
            .slice(0, 2)
            .map((n, i) => (
              <NewsBoxWithDivider
                news={n}
                readMore={miscellaneous.read_more}
                isLast={i === 1}
                key={n.id}
              />
            ))}
      </Box>
      {!!otherNews.length && !mobile && (
        <Divider condensed className={classes.divider} />
      )}
      <Box {...getWrapperStyle(false, mobile, otherNews.length === 3)}>
        {otherNews.map((n, i) => (
          <NewsBoxWithDivider
            news={n}
            readMore={miscellaneous.read_more}
            isLast={i === otherNews.length - 1}
            key={n.id}
          />
        ))}
      </Box>
    </Container>
  );
}

function NewsBoxWithDivider({ news, readMore, isLast, language }) {
  return (
    <>
      <NewsBox news={news} readMore={readMore} />
      {!isLast && (
        <Box
          minHeight="100%"
          width="1px"
          bgcolor={colors.lightGrey}
          mx="30px"
        />
      )}
    </>
  );
}

function NewsBox({ news, readMore }) {
  const { language } = useLanguage();
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexBasis="30%"
      flexGrow={0}
    >
      <Typography paragraph color="textSecondary" variant="body2">
        {new Date(news.first_publication_date).toLocaleString([language], {
          dateStyle: "medium",
        })}
      </Typography>
      <Headline variant="h4" semibold paragraph>
        {richText(news.data.title)}
      </Headline>
      <Typography paragraph color="textSecondary" variant="body2">
        {richText(news.data.summary)}
      </Typography>
      <Box marginTop="auto">
        <Link href={`/news?id=${news.id}`} underline="always">
          {readMore}
        </Link>
      </Box>
    </Box>
  );
}

function getWrapperStyle(hasImage, mobile, hasOtherNews, withMb) {
  if (mobile) {
    return {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridGap: "20px",
      marginBottom: withMb ? "40px" : "0",
    };
  }
  if (!hasImage) {
    return {
      display: "flex",
      justifyContent: hasOtherNews ? "space-between" : "flex-start",
    };
  }
  return {
    display: "grid",
    gridTemplateColumns: "5fr 2fr",
    gridGap: "60px",
  };
}
