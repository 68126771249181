import React, { useEffect } from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { Loader } from "../components/Loader";
import { Container } from "../components/Container";
import { Headline } from "../components/Headline";
import { sortNews } from "../utils/news";
import { richText } from "../utils/richText";
import { useCoreData } from "../utils/useCoreData";
import { getWindow } from "../utils/getWindow";
import { colors } from "../utils/theme";
import { useMobile } from "../utils/useMobile";
import { useLanguage } from "../utils/useLanguage";

export function NewsContent({ id, classes }) {
  const { api } = useCoreData();
  const { news, miscellaneous } = api.data;
  const { loading, allNews } = api;

  if (!allNews || !miscellaneous || loading) return <Loader />;

  const mainNews = (news || []).find((r) => r.id === id);

  const alternateResult =
    !mainNews &&
    (news || []).find(
      (r) =>
        r.alternate_languages.map((l) => l.id).includes(id) &&
        r.lang === api.language
    );
  const actualResult = !mainNews && allNews.find((r) => r.id === id);

  const sideNews =
    !mainNews && alternateResult
      ? []
      : sortNews(
          (news || []).filter(
            (r) =>
              r.lang === api.language && (!mainNews || r.id !== mainNews.id)
          )
        ).slice(0, 3);

  return (
    <Content
      classes={classes}
      mainNews={mainNews}
      sideNews={sideNews}
      misc={miscellaneous}
      actualResult={actualResult}
      alternateResult={alternateResult}
    />
  );
}

function Content({
  mainNews,
  sideNews,
  misc,
  actualResult,
  alternateResult,
  classes,
}) {
  const mobile = useMobile();
  const { language } = useLanguage();
  useEffect(() => {
    const win = getWindow();
    if (win && !mainNews && !actualResult) {
      win.location.replace("/news");
    }
    if (win && !mainNews && alternateResult) {
      win.location.replace("/news?id=" + alternateResult.id);
    }
  }, [mainNews]);

  const newsToShow = mainNews || actualResult;
  if (!newsToShow || alternateResult) return <Loader />;

  return (
    <Container withToolbar withBottomPadding>
      <Box
        display="grid"
        gridTemplateColumns={mobile ? "1fr" : "6fr 2fr"}
        gridGap={mobile ? "40px" : "150px"}
        mt="40px"
      >
        <Box style={{ wordBreak: "break-word" }}>
          {mainNews !== newsToShow && (
            <Box
              bgcolor={colors.lightGrey}
              textAlign="center"
              py="20px"
              mb="30px"
            >
              {misc.article_unavailable_in_language}
            </Box>
          )}
          <Typography paragraph color="textSecondary" variant="body2">
            {new Date(newsToShow.first_publication_date).toLocaleString(
              [language],
              {
                dateStyle: "medium",
              }
            )}
          </Typography>
          {newsToShow.data.image.url && (
            <Box mb="40px">
              <img
                src={newsToShow.data.image.url}
                alt={newsToShow.data.image.alt}
                className={classes.image}
              />
            </Box>
          )}
          <Headline variant="h4" semibold paragraph>
            {richText(newsToShow.data.title)}
          </Headline>
          <Box my="30px">
            <Headline color="textSecondary" variant="body2" paragraph>
              {richText(newsToShow.data.summary)}
            </Headline>
          </Box>
          <Headline className={classes.withParagraph}>
            {richText(newsToShow.data.article)}
          </Headline>
        </Box>
        <div>
          {!!sideNews.length && (
            <>
              <Typography
                className={classes.moreNews}
                variant="body2"
                paragraph
              >
                {misc.more_news}
              </Typography>
              {sideNews.map(({ data, id,first_publication_date }) => (
                <Box key={id} mb="40px">
                  <Typography paragraph color="textSecondary" variant="body2">
                    {new Date(first_publication_date).toLocaleString(
                      [language],
                      {
                        dateStyle: "medium",
                      }
                    )}
                  </Typography>
                  <Headline semibold variant="h5" paragraph>
                    {richText(data.title)}
                  </Headline>
                  <Link href={`/news?id=${id}`} underline="always">
                    {misc.read_more}
                  </Link>
                </Box>
              ))}
            </>
          )}
        </div>
      </Box>
    </Container>
  );
}
